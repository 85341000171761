import { ProductVariantModel } from '~/models/productVariant';
import { CartApiItem } from '~/constants/types/norce';
import { Flag } from '~/constants/types/algolia';
import { NorceFlagCodes } from '~/constants/norceCodes';
import useStripFlagSuffix from '~/composeables/useStripFlagSuffix';

const { stripFlagSuffix } = useStripFlagSuffix();

export type StockLabel = {
  text: string,
  textShort: string,
  color: string,
  type: 'preorder' | 'ondemand' | 'expired' | 'tempSoldout' | 'soldout' | 'active' | ''
}

export default function useStockStatus() {
  const { $t } = useNuxtApp();

  const cartItemIncomingDate = (item: CartApiItem) => {
    const today = new Date().toISOString().split('T')[0];
    const dates = [item.stockStatus]
      .filter((f) => f.estimatedDelivery && f.estimatedDelivery > today)
      .map((m) => m?.estimatedDelivery?.split(' ')[0])
      .sort();
    if (!dates.length) {
      return null;
    }
    return dates.shift();
  };

  const getStockLabelForCartItem = (item: CartApiItem): StockLabel => {
    if (item.onHandValue < 1 && item.stockStatus?.estimatedDelivery) {
      return {
        text: $t('stock.tempSoldout', { date: item.stockStatus?.estimatedDelivery }),
        textShort: $t('stock.tempSoldout', { date: item.stockStatus?.estimatedDelivery }),
        color: 'brightYellow',
        type: 'tempSoldout',
      };
    } else if (item.flags.filter((flag: Flag) => stripFlagSuffix(flag.type) === NorceFlagCodes.PreOrder)?.length > 0) {
      return {
        text: $t('stock.releaseDate', { date: cartItemIncomingDate(item) }),
        textShort: $t('stock.releaseDateWithLineBreak'),
        color: 'warningPrimary',
        type: 'preorder',
      };
    } else if (item.flags.filter((flag: Flag) => stripFlagSuffix(flag.type) === NorceFlagCodes.OnDemand)?.length > 0 && item.onHandValue < 1) {
      return {
        text: item.stockStatus?.leadtimeDayCount ? $t('stock.isOnDemandProduct', { num: item.stockStatus?.leadtimeDayCount }) : $t('stock.onDemandProduct'),
        textShort: item.stockStatus?.leadtimeDayCount ? $t('stock.isOnDemandProductWithLineBreak') : $t('stock.onDemandProduct'),
        color: 'mintPrimary',
        type: 'ondemand',
      };
    } else if (item.onHandValue < 1) {
      return {
        text: $t('stock.soldout'),
        textShort: $t('stock.soldoutShort'),
        color: 'warningPrimary',
        type: 'soldout',
      };
    }

    return { text: '', textShort: '', color: '', type: '' };
  };

  const getStockLabel = (
    variant: ProductVariantModel,
    detailedInstock = false,
    alternativeText = false // category uses alternative texts
  ): StockLabel => {
    if (variant.isPreOrderProduct) {
      return {
        text: $t('stock.releaseDate', { date : variant.incomingDate }),
        textShort: $t('stock.releaseDateWithLineBreak'),
        color: 'warningPrimary',
        type: 'preorder',
      };
    } else if (variant.isOnDemandProduct) {
      const anyInStock = variant.stockStatuses?.some((s) => s.inStock > 0);
      // If there are any in stock, this overrides "on demand"
      if (anyInStock) {
        return {
          text: $t('stock.inStockSendWithinDays'),
          textShort: $t('stock.inStock'),
          color: 'mintPrimary',
          type: 'active',
        };
      } else {
        return {
          text: $t('stock.isOnDemandProduct', { num: variant.stockStatus?.leadTimeDayCount }),
          textShort: $t('stock.isOnDemandProductWithLineBreak'),
          color: 'mintPrimary',
          type: 'ondemand',
        };
      }
    } else if (variant.productHasExpired) {
      return {
        text: $t('stock.productHasExpired'),
        textShort: $t('stock.productHasExpired'),
        color: 'warningPrimary',
        type: 'expired',
      };
    } else if (variant.isTempSoldOut) {
      return {
        text: $t('stock.tempSoldout', { date : variant.incomingDate }),
        textShort: $t('stock.tempSoldoutShort'),
        color: 'warningPrimary',
        type: 'tempSoldout',
      };
    } else if (variant.isActive && variant.stockValue > 1) {
      let text = $t('stock.inStockSendWithinDays');
      let color = 'mintPrimary';
      if (detailedInstock && !alternativeText) {
        if (variant.stockValue >= 100) {
          text = $t('stock.std.over100');
        }
        else if (variant.stockValue >= 50) {
          text = $t('stock.std.over50');
        }
        else if (variant.stockValue >= 25) {
          text = $t('stock.std.over25');
        }
        else {
          text = $t('stock.std.less25');
          color = 'attentionPrimary';
        }
      }
      if (detailedInstock && alternativeText) {
        if (variant.stockValue >= 5) {
          text = $t('stock.alt.over5');
        }
        else {
          text = $t('stock.alt.less5');
          color = 'attentionPrimary';
        }
      }
      //text += variant.stockValue;
      return {
        text,
        textShort: $t('stock.inStock'),
        color,
        type: 'active',
      };
    } else if (variant.isActive && variant.isSoldOut && !variant.hasIncomingDate) {
      return {
        text: $t('stock.soldout'),
        textShort: $t('stock.soldoutShort'),
        color: 'criticalDark',
        type: 'soldout',
      };
    }
    return { text: '', textShort: '', color: '', type: '' };
  };

  return {
    getStockLabelForCartItem,
    getStockLabel,
  };
}
